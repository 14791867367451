import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Row from '../components/row'
import TourBlock from '../components/tourBlock'

import {
  ImgWrapper,
  ImgContainer,
  MaxWidthWrapper
} from '../styledComponents/shared'

const TourDetailsPage = ({ data }) => {
  const tours = data.allToursJson.nodes[0].tour

  return (
    <Layout>
      <SEO
        title='Tour Details'
        description='Our tours are straight forward, but there a few details you need to know before heading to the continent.'
        url='/tour-details'
        image='tour_details.jpg'
      />
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper maxWidth='750' style={{ lineHeight: '1.75' }}>
          <h1 style={{ marginTop: '0.25rem' }}>Tour Pricing and Booking</h1>
          <p>
            We run 2 tours per month from April till October. Each tour is 4
            days in duration.
          </p>
          <p>
            The price of £{(data.site.siteMetadata.tourPrice / 100).toFixed(2)}{' '}
            includes Eurostar, B&amp;B accommodation with secure parking for
            your motorcycle and all museum entrance fees.
          </p>
          <p>
            Pillion passeners are welcome at £
            {(data.site.siteMetadata.tourPillionPrice / 100).toFixed(2)} per
            pillion.
          </p>
          <p>
            The price does not include petrol, food and drink and evening meals.
          </p>
          <p>We can cater for a maximum of 6 riders per tour.</p>
          <p>
            <Link to='/contact'>Contact us</Link> to book today!
          </p>
        </MaxWidthWrapper>
      </Row>
      <Row>
        <ImgContainer maxWidth={data.site.siteMetadata.maxWidth}>
          <ImgWrapper maxHeight='300px'>
            <Img fluid={data.flatGravesRow.childImageSharp.fluid} />
          </ImgWrapper>
        </ImgContainer>
      </Row>
      <Row bgColour='var(--primary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <h1 style={{ marginTop: '0.25rem', textAlign: 'center' }}>
            Upcoming tours
          </h1>
          <TourBlock tours={tours} dropPast />
        </MaxWidthWrapper>
      </Row>
      <Row>
        <ImgContainer maxWidth={data.site.siteMetadata.maxWidth}>
          <ImgWrapper maxHeight='300px'>
            <Img fluid={data.sandBagsRow.childImageSharp.fluid} />
          </ImgWrapper>
        </ImgContainer>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper maxWidth='750' style={{ lineHeight: '1.75' }}>
          <h1 style={{ marginTop: '0.25rem' }}>Details to be aware of</h1>
          <p>
            Thinking of booking? Great! Here are a few things you should be
            aware of.
          </p>
          <h2>Accommodation</h2>
          <p>
            Our choice of accommodation is t'Our Gemeentehuis Poelkapelle. Stjin
            is an excellent host and offers superb food and accommodation. Each
            room has an on suite and sleeps up to 3 people. There is ample
            secure parking for your motorcycle. Our prices are based on room
            sharing additional costs will be incurred if you require your own
            room.{' '}
          </p>
          <h2>What to take</h2>
          <p>You will need to take the following:</p>
          <ul>
            <li>
              Your passport (must have more than 6 months remaining on it)
            </li>
            <li>Your driving licence</li>
            <li>Your motorcycle log book (V5)</li>
            <li>Insurance certificate</li>
            <li>
              International driving permit depending on the outcome of Brexit
              (obtainable from a Post Office)
            </li>
            <li>
              Health insurance (please note the EHIC card will no longer be of
              use in the event of a no deal Brexit)
            </li>
            <li>
              Spare bulbs (if your motorcycle is not equipped with LED lighting)
            </li>
            <li>
              A yellow hi-viz vest. If you are spot checked and do not have a
              vest there is a &euro;11. This fine rises to &euro;135 if you have
              stopped at the roadside for any mechanical reasons or due to an
              accident and you are not wearing one. Pillion passengers also need
              to have one
            </li>
            <li>
              A GB sticker of some kind needs to be as near to (or on) the rear
              registration plate. We appreciate, and sometimes even the French
              police do, that it is difficult to fit a GB plate anywhere on a
              motorcycle
            </li>
            <li>
              At least 3 disposable breathalysers. This is a straneg one as
              although it is law, it is a law that isn't generally enforced
            </li>
            <li>
              European breakdown cover (although we don't use toll roads it's
              worth noting that no breakdown cover covers you or your vehicle
              whilst on toll roads and you will have to pay for recovery)
            </li>
            <li>
              A credit card for fuel (most petrol stations are self service and
              only accept credit cards)
            </li>
            <li>Cash in Euros</li>
            <li>
              All documents <span style={{ fontWeight: 700 }}>MUST</span> be
              originals, photocopies are not permissible
            </li>
            <li>
              It is compulsory that everyone has four reflective strips on their
              helmet when riding in France (although this is very rarely
              enforced), these can be cheaply purchased from eBay or other
              online retailers
            </li>
          </ul>
          <h2>Items we strongly advise you take</h2>
          <p>Not essential but we recommend you also bring:</p>
          <ul>
            <li>Waterproofs</li>
            <li>Puncture repair kit</li>
            <li>Spare tubes and tyre irons if you bike has tubed tyres</li>
            <li>Tools that fit your bike</li>
            <li>Chain lube</li>
            <li>Sunglasses</li>
            <li>Spare gloves</li>
            <li>Visor cleaning kit</li>
          </ul>
          <h2>Local laws</h2>
          <p>
            Brush up on your understanding of European local driving laws before
            hitting the continent.
          </p>
          <p>
            You must ride on the right hand side of the road (obvious yes but
            you'd be surprised by how many forget).
          </p>
          <p>
            Speed limits are in kilometres per hour, so those with digital
            speedometers will need to change them over as unlike analogue
            speedometers km/h isn't shown.
          </p>
          <p>
            Speed cameras are plentiful (though most are forward facing). You
            will get a fine if caught speeding (no points on your licence will
            be added) this can be sent to your home address many months after
            the alleged offence.
          </p>
          <p>
            Satellite navigation devices are allowed but you must disable the
            speed camera warning or you will incur a fine.
          </p>
          <p>
            In France{' '}
            <span style={{ fontStyle: 'italic' }}>priorite a droite</span> is
            often confusing to UK motorists. In towns and villages priority must
            be given to vehicles joining the main carriageway from the right,
            don't be surprised when a vehicle pulls straight out in front of you
            without warning.
          </p>
          <p>
            Although we always advise wearing protective motorcycle clothing
            there are differing laws in Europe. In France you must wear an EU
            type approved helmet (ECER 22/05) and glvoes that have CE approved
            protection. You may ride in anything else i.e. a t-shirt, though we
            strongly advise against it. In Belgium you must wear a helmet (again
            ECER 22/05 type approved), jacket, gloves, motorcycle trousers and
            boots. A hefty roadside fine to be paid in cash will come your way
            if caught not wearing any of the above.
          </p>
          <p>
            Please note although souvenirs are on sale at most museums you
            cannot take back any form of arms or munitions (however well
            decommissioned and documented as such) through the Eurotunnel. The
            French and British port authorities take a very dim view of anyone
            trying to take such items through customs and onto the train, severe
            penalities are in place.
          </p>
        </MaxWidthWrapper>
      </Row>
    </Layout>
  )
}

export default TourDetailsPage

export const query = graphql`
  query TourDetailsPageQuery {
    site {
      siteMetadata {
        title
        tourPrice
        tourPillionPrice
        email
        phone
        maxWidth
      }
    }
    flatGravesRow: file(relativePath: { eq: "flat_graves.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sandBagsRow: file(relativePath: { eq: "sandbags.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allToursJson {
      nodes {
        tour {
          date
          spaces
          booked
        }
      }
    }
  }
`
